import React from "react"
import { Layout } from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"

const submisionsPage = () => (
  <div className="body">
    <Layout>
      <SEO title="Submissions" />
      <div data-w-id="63b8498e-1639-aebb-9ce4-f79485e68895" className="page">
        <section className="section">
          <div className="page-heading-parent">
            <h1>Share Your Work.</h1>
            <div className="line grey"></div>
          </div>
          <h2>
            Submit to <em>the Pub!</em>
          </h2>
          <div className="w-richtext">
            <p>
              We are excited to announce our new theme: <strong>Glass House</strong>!
            </p>
            <p>
              Tell us about the way light seeps in at dawn, how shadows stretch until they disappear, or what
              it's like to feel the sun on your skin. Share with us the ways in which you have grown or how the hidden parts of yourself were illuminated. Do the birds 
              still whistle when you close the window? Can you remember the sound of that summer's big storm? What do you write when you breathe on glass? Look in and 
              look out of the glass house.
            </p>
            <p>
              A theme isn't intended to exclude or restrict submission, it is merely a way to spark your imagination and guide your creative process. 
              ALL submissions are welcome.
            </p>
            <p>
              Submissions are open <strong>February 2nd</strong> through <strong>March 4th</strong>!
            </p>
          </div>
          <div className="button-parent page">
            <Button
              name="Submit Work"
              to="https://docs.google.com/forms/d/e/1FAIpQLScVFsSqf5A_fDpSLn8_RpOpAA5AZP1eoNPw7NLq_FaQVuPHgw/viewform"
            />
          </div>
          <div className="rich-text-block w-richtext">
            <p>‍</p>
            <h2>Submissions Guidelines:</h2>
            <p>
              <em>Remember: </em>you don&#x27;t have to be a student to submit
              to <em>The Pub! </em>If you are an alumni, professor, or staff
              member at Wheaton, we would be honored to receive your work.
              <br />
            </p>
            <br></br>
            <h4>
              Essays—2500 word limit, Chicago Manual of Style, endnotes,
              citations required.
            </h4>
            <p>
              Essays include critical essays, reviews, and interviews. For the
              Essays section, you can either submit a complete essay for
              editing, or submit a 500-word essay proposal which will be coached
              and edited by our Essays editors throughout the semester. The
              first option is best if you already have a piece you wrote for a
              class or another reason and it only need minor edits for
              publication. The second option is best if you have an idea you’ve
              wanted to carry out for a while and The Pub looks like a good
              reason to do it! For the former, the submission process is normal:
              submit before March 21 and you will be contacted about edits and
              copyediting if accepted. For the latter, essay proposals should be
              submitted by March 1 and will be reviewed by the Editorial team.
              If accepted, you will work with the Essays section throughout the
              semester on your piece. This is a great choice if you want
              experience in a collaborative editing process. You are welcome to
              submit more than one piece, but only one essay will be published
              per individual.
            </p>
            <br></br>
            <h4>
              Narrative—2500 word limit, Chicago Manual of Style, endnotes,
              citations required.
            </h4>
            <p>
              Published per semester: 2 or more narrative pieces. Narrative
              accepts both fiction and non-fiction storytelling. You are welcome
              to submit more than one piece, but only one will be published per
              individual
            </p>
            <br></br>
            <h4>Poetry—No word limit.</h4>
            <p>
              Published per semester: 4 or more poetry pieces. Poetry is always a
              popular section of The Pub! Please make sure your document is
              editable and includes your name at the top. You can submit as many
              pieces as you wish, though our maximum for publication is <strong>‍2 per
              person</strong>.
            </p>
            <br></br>
            <p>
              <strong>Art—4 or more artworks.</strong>‍
            </p>
            <p>
              The Pub accepts all mediums of visual artwork for publication. You
              may submit a standalone piece or a series. We believe art to be an
              essential part of intellectual Christian discussion. In order to
              spark discussion, we require artists to submit a statement to
              accompany their work. This statement might be a short piece of
              creative writing, an explanation of your process, an exploration
              of the subject matter, a relation of it to the issue’s themes,
              etc. It does not need to be a stiff or explanatory statement––we
              trust your work speaks for itself. We welcome the collaboration of
              essayists and artists in the exploration of a piece. Please be
              sure you have submitted your highest quality image and your
              statement is in an editable Google Doc.You may submit more than
              one image, but typically only two spreads (4 pages) will be
              devoted to one artist.
              <a href="https://wheatonpub.netlify.app/submissions#">
                <br />
              </a>
            </p>
          </div>
        </section>
      </div>
    </Layout>
  </div>
)
export default submisionsPage
